/* Auth scss */
.loading-dots {
    margin: 4px auto;
    font-size: 4px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: 0;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    transform: translateZ(0);
    display: inline-block;
    margin-left: 10px;
    position: absolute;
    left: calc(50% + 40px);
    top: calc(50% - 6px);

    @-webkit-keyframes load5 {
        0%,
        100% {
            box-shadow: 0em -2.6em 0em 0em #e77386, 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.5), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.7);
        }
        12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.7), 1.8em -1.8em 0 0em #e77386,
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.5);
        }
        25% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.5), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.7),
                2.5em 0em 0 0em #e77386, 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.5),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.7), 1.75em 1.75em 0 0em #e77386,
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        50% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.5), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.7),
                0em 2.5em 0 0em #e77386, -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.5),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.7), -1.8em 1.8em 0 0em #e77386,
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        75% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.5), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.7),
                -2.6em 0em 0 0em #e77386, -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.5),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.7), -1.8em -1.8em 0 0em #e77386;
        }
    }
    @keyframes load5 {
        0%,
        100% {
            box-shadow: 0em -2.6em 0em 0em #e77386, 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.5), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.7);
        }
        12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.7), 1.8em -1.8em 0 0em #e77386,
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.5);
        }
        25% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.5), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.7),
                2.5em 0em 0 0em #e77386, 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.5),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.7), 1.75em 1.75em 0 0em #e77386,
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        50% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.5), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.7),
                0em 2.5em 0 0em #e77386, -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.2),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.5),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.7), -1.8em 1.8em 0 0em #e77386,
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.2), -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        75% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.5), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.7),
                -2.6em 0em 0 0em #e77386, -1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2);
        }
        87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(231, 115, 134, 0.2), 1.8em -1.8em 0 0em rgba(231, 115, 134, 0.2),
                2.5em 0em 0 0em rgba(231, 115, 134, 0.2), 1.75em 1.75em 0 0em rgba(231, 115, 134, 0.2),
                0em 2.5em 0 0em rgba(231, 115, 134, 0.2), -1.8em 1.8em 0 0em rgba(231, 115, 134, 0.5),
                -2.6em 0em 0 0em rgba(231, 115, 134, 0.7), -1.8em -1.8em 0 0em #e77386;
        }
    }
}
.auth {
    background: linear-gradient(180deg, #fd7b97 0%, rgba(253, 128, 109, 0.69) 54.17%, #fd997a 100%);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-wrapper {
        max-width: 90%;
        position: relative;
        margin: 0 auto;
        width: 444px;
        // margin-bottom: 30px;
    }
    .forget-title {
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 30px;
        color: #181818;
    }
    .email-notice {
        margin-bottom: 30px;
        color: #181818;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
    }
}

.login-wrapper .panel {
    &-header {
        margin-bottom: 30px;
    }
    &-body {
        background-color: white;
        padding: 50px 45px;
        padding-bottom: 5px;
        border-radius: 20px 20px 0 0;
        > .error-message {
            padding: 5px 0;
            margin-bottom: 10px;
        }
    }
    &-footer {
        padding-top: 0;
        background-color: white;
        padding-left: 45px;
        padding-right: 45px;
        padding-bottom: 50px;
        border-radius: 0 0 20px 20px;
        .btn-primary {
            background: #e77386;
            border-radius: 16px;
            color: #ffffff;
            width: 100%;
            opacity: 1;
            font-weight: normal;
            font-size: 14px;
            position: relative;
            &:disabled {
                background: #dddddd;
            }
        }
        .forgot-password {
            margin-bottom: 0;
            text-align: center;
            margin-top: 1rem;
            color: var(--primary-color);
            margin-top: 25px;
        }
    }
    .form-group {
        position: relative;
        margin-bottom: 20px;
    }
    .form-control {
        padding-left: 40px;
        font-weight: 500;
        border-color: #ffe8de;
        &::placeholder {
            color: #e0e5e9;
            font-weight: normal;
        }
    }
    .pre-icon {
        box-sizing: border-box;
        width: 100%;
        background-size: 21px 16px;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        left: 10px;
        top: 14px;
        width: 21px;
        height: 16px;
        display: inline-block;
    }
    .icon {
        &-email {
            background-image: url("../images/email-icon.svg") !important;
        }
        &-password {
            background-image: url("../images/password-icon.svg") !important;
        }
    }
}

.login-wrapper .panel-body .input-field label {
    left: 0;
}

.login-wrapper .panel .panel-header {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.login-wrapper .panel .panel-header h5 {
    margin: 10px 0px;
}

.login-wrapper .panel .panel-body .remember-forgot-wrapper {
    margin: 0px;
    height: 50px;
}

.login-wrapper .panel .panel-body .remember-forgot-wrapper .remember-me,
.login-wrapper .panel .panel-body .remember-forgot-wrapper .forgot-password {
    float: left;
    width: 50%;
    margin-bottom: 0px;
}

@media (max-width: 400px) {
    .login-wrapper .panel .panel-body .remember-forgot-wrapper .remember-me,
    .login-wrapper .panel .panel-body .remember-forgot-wrapper .forgot-password {
        width: 100%;
        text-align: center;
    }
}

.login-wrapper .panel .panel-body .remember-forgot-wrapper .forgot-password a {
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
    color: #9e9e9e;
    display: block;
}

.login-wrapper .panel .panel-body .remember-forgot-wrapper .forgot-password a i {
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
    color: #909090;
    margin-right: 2px;
    vertical-align: -5px;
}

.login-wrapper .panel .panel-body .remember-forgot-wrapper .forgot-password a:hover {
    color: #747474;
}

.login-wrapper .panel .panel-body .remember-forgot-wrapper .forgot-password a:hover i {
    color: #707070;
}

@media (min-width: 401px) {
    .login-wrapper .panel .panel-body .remember-forgot-wrapper .forgot-password a {
        margin-top: -2px;
        text-align: right;
    }
}

@media (max-width: 400px) {
    .login-wrapper .panel .panel-body .remember-forgot-wrapper {
        height: 92px;
    }
}

.login-wrapper .panel .panel-footer-social {
    padding: 15px;
    text-align: center;
}

.login-wrapper .panel .panel-footer-social .btn-floating {
    width: 34px;
    height: 34px;
    line-height: 34px;
    margin: 0px 5px;
}

.login-wrapper .panel .panel-footer-social .btn-floating i.fa {
    line-height: 34px;
    vertical-align: inherit;
    font-size: 1rem;
}

.login-wrapper .panel .panel-footer {
    font-size: 0.9em;
}

@media (max-width: 480px) {
    .login-wrapper {
        margin: 2rem auto;
    }
}

@media (max-width: 320px) {
    .login-wrapper .panel-footer .btn-flat {
        display: block;
        text-align: center;
        width: 100%;
    }

    .login-wrapper .panel-footer .btn-flat:nth-child(n + 2) {
        margin-top: 5px;
    }
}

.container .login-wrapper {
    max-width: 100%;
}

.panel-body .input-field {
    margin-bottom: 20px;
}

.error-message {
    color: #d00a2c;
    font-size: 13px;
    margin-top: 5px;
    display: inline-block;
}

.input-field input {
    margin-bottom: 0;
}

.login-wrapper .panel-footer button[disabled="disabled"]:hover {
    border-color: #999;
}

.login-wrapper .panel .panel-footer {
    position: initial;
}

.login-wrapper .panel .panel-header .logo-wrap {
    max-width: 150px;
    margin: 0 auto;
}

.forget-pwd:not(.disabled) .panel-footer button.reset-btn:hover {
    color: #fff;
}
input[type="checkbox"] + label {
    padding-left: 25px;
}
input[type="checkbox"]:not(.filled-in) + label:after {
    width: 18px;
    height: 18px;
    transform: none;
    border: 1px solid #5a5a5aa8;
}

input[type="checkbox"]:checked + label:after {
    border: 1px solid #009fe3;
    background: #009fe3;
}
input[type="checkbox"] + label:before {
    display: none;
}
input[type="checkbox"]:checked + label:before {
    display: block;
    top: 1px;
    left: 0px;
    width: 7px;
    height: 13px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    z-index: 9;
}
.auth-change {
    .login-wrapper .panel .form-control {
        &::placeholder {
            font-size: 14px;
        }
    }
}
/* End Auth scss */

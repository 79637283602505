/* button scss */
.btn {
    padding: 0.657rem 0.75rem;
    border: none;
    box-shadow: none;
    outline: none;

    &:disabled {
        background-color: #e7e7e7;
    }

    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
    }

    &-primary {
        background-color: var(--primary-color);
        color: #fff !important;
        border-radius: 16px;

        &:active,
        &:focus {
            box-shadow: none;
            outline: none;
            background-color: var(--primary-color);
            color: #fff;

            &:not(:focus-visible) {
                box-shadow: none;
                outline: none;
            }
        }

        &:hover {
            background-color: var(--primary-color);
            color: #fff;
            opacity: 0.9;
        }
    }

    &-secondary {
        background-color: transparent;
        color: var(--primary-color) !important;

        &:hover {
            background-color: transparent;
            color: var(--primary-color);
            opacity: 0.8;
        }
    }

    &-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--primary-color);
    }

    &-purple {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--link-color);
    }

}

.bd-content {
    .btn {
        padding-left: 20px;
        padding-right: 20px;

        &-primary {
            border-radius: 12px;
        }

        &-border {
            background-color: transparent;
            border: 1px solid #FFE8DE;
            border-radius: 20px;
        }
    }
}

/*End button scss */

/* Profile user  */
.section-profile-user {
    .form-wrap {
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
    }
    .label-wrap {
        width: 160px;
    }
    .label-wrap-content {
        width: calc(100% - 160px);
    }
    .label {
        font-weight: 500;
        font-size: 14px;
        color: #565656;
        margin-bottom: 0;
        &-text {
            font-weight: 400;
            font-size: 14px;
            color: #565656;
            margin-bottom: 0;
        }
    }
    .btn-text {
        color: var(--primary-color);
        text-decoration: underline;
        font-weight: 400;
    }
    .modalTagAdd {
        .modal-title {
            font-size: 18px;
        }
        .modal-footer {
            .actions {
                margin: 0;
            }
        }
    }
}
/* End Profile user  */

.dropzone {
    .dz-preview:hover .dz-details {
        display: none;
    }
    .dz-preview {
    }
}
.vue-dropzone {
    border: 1px solid var(--input-border);
    padding: 0;
    padding: 0;
}
.dropzone .dz-preview .dz-details {
    display: none;
}
.dropzone .dz-preview.dz-processing .dz-progress {
    display: none;
}
.vue-dropzone > .dz-preview .dz-success-mark {
    display: none;
}
.dropzone .dz-preview .dz-error-mark {
    display: none;
}
.dropzone-area {
    input[type="file"] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .hidden.image {
        display: inline-block !important;
    }

    .display-inline {
        display: inline-block;
        vertical-align: middle;
    }

    .trigger-element {
        min-height: 210px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--input-border);
        text-align: center;
        background-color: #ffe8de45;
    }

    .drop {
        padding: 0;
        position: relative;

        &.image {
            border: none;
            background-color: transparent;
            img {
                width: 100%;
            }
        }
    }
}
.vue-dropzone {
    &.dropzone-single {
        padding: 0;
        background: #ffe8de45;
        border: 1px solid var(--input-border);
        position: relative;
        + .remove-image {
            display: none;
            font-weight: 500;
            font-size: 14px;
            color: var(--primary-color);
            margin-top: 15px;
        }
        .dz-message {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
            width: 100%;
        }
        &.dz-started {
            border: none;
            + .remove-image {
                display: block;
            }
        }
        .dz-preview.dz-image-preview {
            margin: 0;
            width: 100%;
        }
    }
}
.vue-dropzone > .dz-preview .dz-remove {
    opacity: 1;
    display: block;
    position: initial;
}

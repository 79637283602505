/* Onboarding scss */
.section-onboard {
    .form-control {
        // display: none;
    }
    // header.v-sheet {
    //     display: none;
    // }
}
/*End Onboarding scss */

.section {
    .v-dialog__content {


        label {
            font-size: 16px;
            color: #989898 !important;
            display: block;
            margin-bottom: 10px;
            position: initial !important;
            width: 100% !important;
            height: auto !important;
          line-height: normal;
            transform: none !important;
        }
        .v-input {
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 15px;
            .v-text-field__slot {
                flex-direction: column;
                width: 100%;
                margin-bottom: 0;
            }
            input:not([readonly="readonly"]),
            textarea {
                padding: 11px 15px;
                background-color: #fff;
                color: black;
                border: 1px solid var(--input-border);
                font-size: 16px;
                border-radius: 14px;
                width: 100%;
                max-height: initial;
            }

            textarea {
                min-height: 5.5rem;
                resize: none;
                line-height: 1.2;
            }
            .v-text-field__details {
                display: none;
            }
        }
    }
}
.v-dialog > .v-card > .v-card__actions {
    padding-bottom: 30px !important;

    .btn {
        outline: none;
        box-shadow: none;
    }
    .btn-secondary {
        background-color: transparent;
        &:active {
            background-color: transparent;
        }
    }
}

.section-onboard {
    .v-data-table {
        table {
            tbody {
                .thumb-wrap {
                    .board-img {
                        height: 50px;
                        width: 50px;
                       border-radius: 14px;
                    }
                }
            }
        }
    }
}

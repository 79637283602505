/* packages */
@import '~bootstrap/scss/bootstrap';
@import "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css";
@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import '~vuetify/dist/vuetify.css';
// @import '~vuetify/dist/vuetify.min.css';





/* custom css */
@import 'partials/_font.scss';
@import 'partials/_variables.scss';
@import'partials/_mixins.scss';
@import'partials/_animation.scss';
@import'partials/_loader.scss';
@import'partials/_btn.scss';
@import'partials/_typography.scss';
@import'partials/_vuetifyTable.scss';
@import'partials/_modal.scss';
@import'partials/_common.scss';
@import'partials/_textarea.scss';
@import'partials/_sidebar.scss';
@import'partials/_vueSelectCustom.scss';
@import'partials/_dropzoneCustom.scss';
@import'partials/_lightboxCustom.scss';
@import'partials/_datepickerCustom.scss';
@import'partials/_panel.scss';
@import'partials/_header.scss';
@import'partials/_footer.scss';
@import'partials/auth.scss';

@import'partials/_dashboard.scss';
@import'partials/_onboarding.scss';
@import'partials/_users.scss';
@import'partials/_pets.scss';
@import'partials/_tableFooterCustom.scss';
@import'partials/_vaccine.scss';
@import'partials/_breed.scss';
@import'partials/_nutrition.scss';
@import'partials/merchant.scss';
@import'partials/firstaid.scss';
@import'partials/_profileUser.scss';


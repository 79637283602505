.modal {
    &.small {
        .modal-dialog {
            max-width: 740px;
        }
    }
    &-title {
        font-weight: 500;
        font-size: 22px;
        color: #181818;
    }
}
.v-dialog {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        // background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
    }
}
/* onboarding  modals */
.edit-onbaord {
    .image-area {
        width: 310px;
    }
    .field-area {
        width: calc(100% - 310px);
    }
}
/*End onboarding  modals */

span.vs__selected {
    padding-left: 15px;
}
div.vs--single.vs--open .vs__selected {
    position: initial;
    opacity: 1;
}
.section {
    .v-dialog__content {
        .v-dialog.modal-actionDelete {
            max-width: 460px !important;
            border-radius: 20px;
            .v-card {
                > .v-card__title {
                    padding-top: 30px;
                    font-weight: 500;
                    font-size: 22px;
                    color: #181818;
                    margin-bottom: 15px;
                }
                > .v-card__subtitle {
                    font-weight: 300;
                    font-size: 16px;
                    color: #181818;
                    padding-bottom: 5px;
                }
                .action {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;
                    .btn {
                        border-radius: 12px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        &-primary {
                            min-width: 100px;
                            text-align: center;
                            margin-left: 15px;
                        }
                        &-cancel {
                            &:hover,
                            &:active,
                            &:focus {
                                color: var(--primary-color);
                            }
                        }
                    }
                }
            }
        }
    }
}
.v-card__text {
    .v-select__slot {
        flex-wrap: wrap;
        flex-direction: column;
        .v-select__selections {
            border: 1px solid var(--input-border);
            font-size: 16px;
            border-radius: 14px;
            width: 100%;
            max-height: initial;
            padding: 5px 15px !important;
        }
        .v-input__append-inner {
            position: absolute;
            top: 40px;
            right: 10px;
        }
    }
}
#img-upload {
    input[type="file"] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
    .trigger-element {
        min-height: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e773860d;
        border: 1px solid var(--input-border);
        &.hidden {
            display: none;
            + .image {
                .img-wrap {
                    height: 280px;
                    width: 280px;
                    border-radius: 16px;
                    position: relative;
                    overflow: hidden;
                }
                .img {
                    display: block;
                    border: none;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                }
            }
        }
    }

    .hidden.image {
        display: block !important;
    }
    .btn-remove {
        display: block;
        font-weight: 500;
        font-size: 14px;
        margin-top: 15px;
        color: #e77386;
    }

    .display-inline {
        display: inline-block;
        vertical-align: middle;
    }

    .img {
        border: 1px solid #f6f6f6;
        display: inline-block;
        height: auto;
        width: 100%;
        display: block;
    }

    .image {
        .img {
            display: none;
        }
    }

    .drop {
        background-color: #f2f2f2;
        border: 4px dashed #ccc;
        background-color: #f6f6f6;
        border-radius: 2px;
        height: 100%;
        max-height: 400px;
        max-width: 600px;
        width: 100%;
    }
}

/* usrs modal */
.v-dialog__content {
    .v-dialog {
        box-shadow: none;
    }
}
.v-dialog {
    .show-pet {
        border-radius: 20px;
        .v-card__title {
            padding: 20px 30px;
            padding-bottom: 0px;
        }
        .v-card__text {
            padding: 30px;
            padding-top: 0;
        }
        .user-title {
            font-weight: 500;
            font-size: 18px;
            color: #181818;
        }
        ul {
            padding-left: 0;
            li {
                padding-top: 15px;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #e7e7e7;
                    padding-bottom: 15px;
                }
            }
            .list-wrap {
                display: flex;
                align-items: center;
            }
            figure.thumb-wrap {
                border: 1px solid var(--primary-color);
                box-sizing: border-box;
                border-radius: 50%;
                overflow: hidden;
                width: 60px;
                height: 60px;
                position: relative;
                > img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .content-wrap {
                padding-left: 15px;
                width: calc(100% - 60px);
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .text-wrap {
                .pet {
                    &-name {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #181818;
                        margin-bottom: 5px;
                    }
                    &-breed {
                        font-size: 14px;
                        color: #6a6a6a !important;
                        margin-bottom: 0;
                    }
                }
            }
            .action {
                display: flex;
                align-items: center;
                .link {
                    color: #e77386 !important;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;

                    .arrow-icon {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.000611764 6.001C0.000611782 5.80209 0.0796294 5.61132 0.220282 5.47067C0.360934 5.33002 0.5517 5.251 0.750613 5.251L9.44011 5.251L6.21961 2.032C6.14988 1.96227 6.09457 1.87949 6.05683 1.78838C6.01909 1.69727 5.99967 1.59962 5.99967 1.501C5.99967 1.40239 6.01909 1.30474 6.05683 1.21363C6.09457 1.12252 6.14988 1.03973 6.21961 0.970003C6.28934 0.90027 6.37213 0.844956 6.46324 0.807217C6.55435 0.769478 6.652 0.750056 6.75061 0.750056C6.84923 0.750056 6.94688 0.769478 7.03799 0.807217C7.1291 0.844956 7.21188 0.90027 7.28161 0.970003L11.7816 5.47C11.8515 5.53967 11.9069 5.62244 11.9447 5.71355C11.9825 5.80467 12.002 5.90235 12.002 6.001C12.002 6.09966 11.9825 6.19734 11.9447 6.28846C11.9069 6.37957 11.8515 6.46234 11.7816 6.532L7.28161 11.032C7.14078 11.1728 6.94978 11.252 6.75061 11.252C6.55145 11.252 6.36044 11.1728 6.21961 11.032C6.07878 10.8912 5.99967 10.7002 5.99967 10.501C5.99967 10.3018 6.07878 10.1108 6.21961 9.97L9.44011 6.751L0.750613 6.751C0.5517 6.751 0.360934 6.67199 0.220282 6.53133C0.0796294 6.39068 0.000611747 6.19992 0.000611764 6.001Z' fill='%23E77386'/%3E%3C/svg%3E");
                        width: 12px;
                        height: 10.5px;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}
.v-dialog {
    .modal-actionDelete {
        .v-card__title {
            font-weight: 500;
            font-size: 22px;
            color: #181818;
            letter-spacing: 0;
            line-height: normal;
            padding: 20px 30px;
            padding-bottom: 10px;
            margin-bottom: auto;
        }
        .v-card__subtitle {
            font-weight: 400;
            font-size: 16px;
            color: #181818;
            padding: 0px 30px;
            letter-spacing: 0;
        }
        .v-card__actions {
            display: flex;
            justify-content: flex-end;
            padding-top: 10px;
            .btn {
                min-height: 40px;
                font-weight: 500;
                padding: 11px 18px;
            }
        }
    }
}
.v-dialog .user--show-modal {
    border-radius: 20px;
    .modal-head {
        padding: 20px 30px;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .modal-left {
            display: flex;
            align-items: center;
        }
        .status {
            margin-left: 20px;
            border-radius: 8px;
            padding: 5px 10px;
            display: inline-block;
            margin-right: 15px;
            &.active {
                color: #57daa1;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                background: #ddf8ec;
            }
        }
        .card-title {
            margin-bottom: 0;
            margin-right: 15px;
        }
        .location {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--text-color);
            margin-right: 15px;
        }
        .reg-date {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--text-color);
        }
        .btn-text {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .modal-body {
        padding: 30px 30px;
        padding-top: 25px;
        .form-wrap {
            label {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #989898;
                display: inline-block;
                width: auto !important;
                margin-right: 5px;
            }
        }
        .image-wrap {
            border-radius: 25px;
            position: relative;
            overflow: hidden;
            padding-bottom: 100%;
            > img {
                position: absolute;
                left: 0;
                top: 0;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
        .text-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            .form-wrap {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
        .pet-details {
            background: #ffffff;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            padding: 30px;
            margin-top: 40px;
            .pet-lists {
                display: flex;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-right: -15px;
                .pet-list {
                    border-bottom: 0;
                    width: calc(100% / 2);
                    padding: 10px 15px;
                }
            }
        }
    }
    .secondary-detail {
        margin-top: 20px;
    }
    .v-card__text {
        padding: 30px;
        padding-top: 0;
    }
    .user-title {
        font-weight: 500;
        font-size: 18px;
        color: #181818;
    }
    ul {
        padding-left: 0;
        li {
            padding-top: 15px;

            &:not(:last-of-type) {
                border-bottom: 1px solid #e7e7e7;
                padding-bottom: 15px;
            }
        }
        .list-wrap {
            display: flex;
            align-items: center;
        }
        figure.thumb-wrap {
            border: 1px solid var(--primary-color);
            box-sizing: border-box;
            border-radius: 50%;
            overflow: hidden;
            width: 60px;
            height: 60px;
            position: relative;
            > img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .content-wrap {
            padding-left: 15px;
            width: calc(100% - 60px);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .text-wrap {
            .pet {
                &-name {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #181818;
                    margin-bottom: 5px;
                }
                &-breed {
                    font-size: 14px;
                    color: #6a6a6a !important;
                    margin-bottom: 0;
                }
            }
        }
        .action {
            display: flex;
            align-items: center;
            .link {
                color: #e77386 !important;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                display: flex;
                align-items: center;

                .arrow-icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.000611764 6.001C0.000611782 5.80209 0.0796294 5.61132 0.220282 5.47067C0.360934 5.33002 0.5517 5.251 0.750613 5.251L9.44011 5.251L6.21961 2.032C6.14988 1.96227 6.09457 1.87949 6.05683 1.78838C6.01909 1.69727 5.99967 1.59962 5.99967 1.501C5.99967 1.40239 6.01909 1.30474 6.05683 1.21363C6.09457 1.12252 6.14988 1.03973 6.21961 0.970003C6.28934 0.90027 6.37213 0.844956 6.46324 0.807217C6.55435 0.769478 6.652 0.750056 6.75061 0.750056C6.84923 0.750056 6.94688 0.769478 7.03799 0.807217C7.1291 0.844956 7.21188 0.90027 7.28161 0.970003L11.7816 5.47C11.8515 5.53967 11.9069 5.62244 11.9447 5.71355C11.9825 5.80467 12.002 5.90235 12.002 6.001C12.002 6.09966 11.9825 6.19734 11.9447 6.28846C11.9069 6.37957 11.8515 6.46234 11.7816 6.532L7.28161 11.032C7.14078 11.1728 6.94978 11.252 6.75061 11.252C6.55145 11.252 6.36044 11.1728 6.21961 11.032C6.07878 10.8912 5.99967 10.7002 5.99967 10.501C5.99967 10.3018 6.07878 10.1108 6.21961 9.97L9.44011 6.751L0.750613 6.751C0.5517 6.751 0.360934 6.67199 0.220282 6.53133C0.0796294 6.39068 0.000611747 6.19992 0.000611764 6.001Z' fill='%23E77386'/%3E%3C/svg%3E");
                    width: 12px;
                    height: 10.5px;
                    margin-left: 6px;
                }
            }
        }
    }
}

.v-dialog {
    .modalTagAdd {
        border-radius: 20px;
        .modal-head {
            padding: 20px 30px;
            padding-bottom: 0px;

            .card-title {
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
        .modal-body {
            padding: 30px 30px;
            padding-top: 25px;
            .form-wrap {
                margin-bottom: 20px;
                &.last {
                    margin-bottom: 0;
                }
                label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #989898;
                    display: inline-block;
                    width: auto !important;
                    margin-right: 5px;
                }
                input {
                    width: 100%;
                }
            }
        }
        .modal-footer {
            padding: 30px 30px;
            padding-top: 0;
            border-top: 0 solid #000;
            .actions {
                margin: 0;
            }
            .btn-primary {
                &:disabled {
                    background-color: var(--primary-color);
                }
            }
            .loading-dots {
                position: relative;
                left: unset;
                top: 1px;
            }
        }
    }
}

.v-dialog {
    .modalAddAdmin {
        border-radius: 20px;
        .modal-head {
            padding: 20px 30px;
            padding-bottom: 0px;

            .card-title {
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
        .modal-body {
            padding: 30px 30px;
            padding-top: 25px;
            .form-wrap {
                margin-bottom: 20px;
                &.last {
                    margin-bottom: 0;
                }
                label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #989898;
                    display: inline-block;
                    width: auto !important;
                    margin-right: 5px;
                }
                input {
                    width: 100%;
                }
            }
        }
        .modal-footer {
            padding: 30px 30px;
            padding-top: 0;
            padding-bottom: 25px;
            border-top: 0 solid #000;
            .actions {
                margin: 0;
            }
            .btn-primary {
                &:disabled {
                    background-color: var(--primary-color);
                }
            }
            .loading-dots {
                position: relative;
                left: unset;
                top: 1px;
            }
        }
    }
}
/*End users modal */

/* Header scss */
.main-navbar {
    background: #fbedf1;
    padding-top: 17px;
    padding-bottom: 17px;
    position: relative;
    z-index: 91;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    .user-area {
        position: relative;
        .dropdown-menu {
            top: calc(100% + 14px);
            border: 1px solid #ffd9c8;
            padding-top: 0;
            padding-bottom: 0;
            .dropdown-item {
                transition: all ease 0.4s;
                padding-top: 8px;
                padding-bottom: 8px;
                &:hover,
                &:active,
                &:focus {
                    background-color: var(--primary-color);
                    color: #fff;
                }
            }
        }
    }

    .user {
        &-toggle {
            display: flex;
            align-items: center;
            position: relative;
            padding-right: 45px;
            &::after {
                background-image: url("../images/icons/user-down.svg");
                background-repeat: no-repeat;
                background-position: center;
                padding: 0;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                width: 14px;
                height: 12px;
                margin: 0;
                vertical-align: middle;
            }
            .rounded-pill {
                width: 40px;
                height: 40px;
            }
            .user_character {
                height: 40px;
                width: 40px;
                background: var(--primary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: white;
                    text-transform: uppercase;
            }
            .text-wrap {
                padding-left: 15px;
            }
        }
        &-name {
            font-weight: 500;
            font-size: 14px;
            color: #181818;
            display: block;
        }
        &-role {
            display: block;
            font-weight: 300;
            font-size: 14px;
            color: #181818;
        }
    }
}

/*end Header scss */

/* First aid scss */
.section-firstAid {
    .toolbar-right {
        column-gap: 15px;
    }
    .nutrition-import-area {
        .form-group {
            .field-wrap {
                display: inline-flex;
                align-items: center;
                .file-area {
                    display: flex;
                    > label {
                        flex: 1 1 100%;
                        max-width: 108px;
                        border: 1px solid var(--input-border);
                        border-radius: 16px 0 0 16px;
                        padding: 10px 15px;
                    }
                }
                .file-name {
                    margin-right: 10px;
                    border: 1px solid var(--input-border);
                    border-radius: 0 16px 16px 0px;
                    padding: 10px 15px;
                    display: inline-block;
                    min-width: 216px;
                    width: 100%;
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-left: -1px;
                }
                .actions {
                    button.btn {
                        position: relative;
                        &:disabled {
                            background-color: var(--primary-color);
                        }
                        .loading-dots {
                            position: relative;
                            top: 1px;
                            right: unset;
                            left: unset;
                        }
                    }
                }
            }
            .form-title-wrap {
                display: none;
            }
            .import-action {
                a {
                    svg {
                        width: 24px;
                        height: 24px;
                        fill: white;
                        padding: 3px;
                    }
                }

                > input {
                    display: none;
                }
            }
        }
    }
}

.section-firstAid-create {
    .panel-body .form-group input[type="url"] {
        padding: 11px 10px;
        max-height: 48px;
        line-height: 1.5;
    }

    .first-aid-form-wrapper {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 26px;
        padding: 30px;
        margin-bottom: 30px;
        .form-group {
            &.disabled {
                pointer-events: none;
                cursor: crosshair;
                opacity: 0.7;
            }
            .drop {
                label.btn {
                    margin-bottom: 0;
                    width: 100%;
                }
                .image {
                    height: 100%;
                    .img-wrap {
                        min-height: 160px;
                        border-radius: 16px;
                        overflow: hidden;
                    }
                    .btn-remove {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cpath d='M7.64539 6.50286L12.7534 1.39425C13.5176 0.629994 12.3269 -0.4745 11.6059 0.246595L6.49786 5.35266L1.38728 0.246595C0.625639 -0.517664 -0.481272 0.673158 0.239744 1.39425L5.34779 6.50286L0.237205 11.6115C-0.496505 12.3453 0.638333 13.5056 1.38474 12.7591L6.49532 7.65052L11.6059 12.7591C12.3396 13.4929 13.4897 12.3453 12.7534 11.6115L7.64539 6.50286Z' fill='white'/%3E%3C/svg%3E");
                        font-size: 0;
                        width: 22px;
                        height: 22px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: #00000066;
                        border-radius: 5px;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
    .step-form-wrapper {
        border-top: 1px solid var(--table-border);
        padding-top: 30px;
        .step-form {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
            .step-title {
                font-size: 18px;
                font-weight: 600;
            }
            &-wrap {
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
                border-radius: 26px;
                padding: 30px;
                position: relative;
                > .btn-text {
                    cursor: pointer;
                    position: absolute;
                    right: 30px;
                    top: 30px;
                }
            }

            .actions {
                margin-top: 25px;
                display: flex;
                justify-content: space-between;
                .btn-border {
                    border: 1px solid var(--primary-color);
                    padding: 12px 25px;
                    border-radius: 12px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: 8px;
                        path {
                            fill: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
}

.section-firstAid-view {
    .switch {
        position: relative;
        display: inline-block;
        margin: 0 5px;
    }

    .switch > span {
        position: absolute;
        top: 9px;
        pointer-events: none;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        width: 50%;
        text-align: center;
    }

    .switch input.check-toggle-round-flat:checked ~ .off {
        color: var(--primary-color);
    }

    .switch input.check-toggle-round-flat:checked ~ .on {
        color: #fff;
    }

    .switch > span.on {
        left: 0;
        padding-left: 2px;
        color: var(--primary-color);
    }

    .switch > span.off {
        right: 0;
        padding-right: 4px;
        color: #fff;
    }

    .switch .check-toggle {
        position: absolute;
        margin-left: -9999px;
        visibility: hidden;
    }
    .switch .check-toggle + label {
        display: block;
        position: relative;
        cursor: pointer;
        outline: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .switch input.check-toggle-round-flat + label {
        padding: 2px;
        width: 100px;
        height: 35px;
        background-color: var(--primary-color);
        -moz-border-radius: 60px;
        -ms-border-radius: 60px;
        -o-border-radius: 60px;
        border-radius: 60px;
    }

    .switch input.check-toggle-round-flat + label:after {
        display: block;
        position: absolute;
        content: "";
    }

    .switch input.check-toggle-round-flat + label:before {
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        background-color: var(--primary-color);
        -moz-border-radius: 60px;
        -ms-border-radius: 60px;
        -o-border-radius: 60px;
        border-radius: 60px;
    }
    .switch input.check-toggle-round-flat + label:after {
        top: 4px;
        left: 4px;
        bottom: 4px;
        width: 48px;
        height: 28px;
        background-color: #fff;
        -webkit-border-radius: 52px;
        -moz-border-radius: 52px;
        -ms-border-radius: 52px;
        -o-border-radius: 52px;
        border-radius: 52px;
        -webkit-transition: margin 0.2s;
        -moz-transition: margin 0.2s;
        -o-transition: margin 0.2s;
        transition: margin 0.2s;
        border-color: var(--primary-color);
    }

    .switch input.check-toggle-round-flat:checked + label:after {
        margin-left: 44px;
    }
    .first-aid-form-wrapper {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 26px;
        padding: 30px;
    }
    .title-with-back {
        width: 100%;
    }
    .panel-header {
        max-width: 1010px;
        .panel-title {
            flex: 1 1 calc(100% - 100px);
        }
    }
    .panel-head {
        display: flex;
        align-items: center;
        .title-wrap {
            text-align: center;
            width: 100%;
        }

        .panel-title {
            font-size: 16px;
            font-weight: 500;
        }
    }
    .btn-text {
        color: var(--primary-color);
    }
    .first-aid-form-wrapper {
        .title-wrap {
            text-align: center;
            padding-right: 115px;
            .title {
                font-weight: 500;
                font-size: 18px !important;
                color: var(--title-color);
                font-family: var(--body-font) !important;
            }
        }
        .action-wrap {
            position: absolute;
            right: 30px;
            top: 35px;
            column-gap: 20px;
            display: flex;
            .btn-purple {
                color: var(--link-color);
            }
        }
        .content-wrap {
            color: var(--title-color);
            font-weight: 400;
            font-size: 16px;
        }
        .first-aid-desc {
            margin-bottom: 30px;
        }
        .first-aid-video {
            margin-bottom: 30px;
            width: 100%;
            position: relative;
            padding-bottom: 56%;
            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .first-aid-image {
            margin-bottom: 30px;
            width: 100%;
            position: relative;
            img {
                display: block;
            }
        }
        .first-aid-steps {
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0;
            }
            .step-title {
                font-weight: 500;
                font-size: 16px;
                color: #e77386;
            }
        }
    }
}

/*End First aid scss */

/* root color */

:root {
    --primary-color: #e77386;
    --title-color: #181818;
    --placeholeder-color: #565656;
    --text-color: #565656;
    --link-color: #b593e7;
    --background-color: #d6d6d6;
    --green-color: #57daa1;
}

/* button color */
:root {
    --btn-primary: #e77386;
    --border-hover: #ff5959;
}

/* status color */
:root {
    --success: #57daa1;
}

:root {
    --body-font: "Rubik", sans-serif;
}

:root {
    --input-border: #ffe8de;
    --border-color: #e7e7e7;
}
:root {
    --table-border: #e7e7e7;
    --table-background: #f8f8f8;
}

/* Merchant scss */
.section-merchant {
    .v-data-table {
        .v-toolbar__content {
            @include bp(sm) {
                align-items: flex-start;
            }
        }
    }
    .toolbar-left {
        .search-toolbar-area {
            @include bp(md) {
                padding-left: 30px;
            }
            @include bp(sm) {
                padding-left: 0;
            }
        }
        .search-toolbar-area > .v-input input {
            @include bp(lg) {
                min-width: 300px;
            }
            @include bp(md) {
                min-width: 265px;
            }
        }
    }
    .toolbar-right {
        > label {
            font-size: 14px;
            font-weight: 400;
            padding-right: 12px;
            color: var(--text-color);
            @include bp(sm) {
                margin-bottom: 15px;
                margin-top: 8px;
            }
        }
        .url-area {
            border: 1px solid #ffe8de;
            padding: 10px 15px;
            border-radius: 14px;
            display: inline-flex;
            align-items: center;
            position: relative;
            font-size: 14px;
            &::before {
                content: "";
                height: 100%;
                width: 1px;
                background-color: #ffe8de;
                position: absolute;
                right: 45px;
            }
        }
    }
    .copy-icon {
        position: relative;
        cursor: pointer;
        margin-left: 15px;
        line-height: normal;
        padding-left: 15px;
        .copied-text {
            position: absolute;
            right: 50%;
            bottom: calc(100% + 12px);
            font-size: 12px;
            background: var(--title-color);
            color: white;
            padding: 5px 10px;
            border-radius: 16px;
            transform: translateX(50%);
            &::before {
                content: "";
                border-color: #181818 transparent transparent transparent;
                display: inline-block;
                position: absolute;
                top: 23px;
                left: 60%;
                transform: translateX(-50%);
                border-style: solid;
                border-width: 8px 6px 0 6px;
            }
            &.active {
                opacity: 1;
            }
        }
    }
    .bottom-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .tab-wrap {
            margin-top: 5px;

            position: relative;
            border-bottom: 1px solid #e7e7e7;
            display: inline-block;

            .v-ripple__container {
                display: none !important;
            }
            .v-tab {
                background-color: transparent;
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                padding-left: 10px;
                padding-right: 10px;
                box-shadow: none;
                &:not(:last-child) {
                    margin-right: 15px;
                }
                &:hover,
                &:focus {
                    &::before {
                        opacity: 0;
                    }
                }
                &:not(.v-tab--active) {
                    color: #565656;
                }
                &.v-tab--active {
                    color: var(--primary-color);
                }
            }

            .v-tabs-slider {
                border-radius: 4px 4px 0px 0px;
            }
        }
    }
}
/* End Merchant scss */

/* nutrition scss */
.section-nutrition {
    .title-with-search {
        display: flex;
        align-items: center;

        @include up(sm) {
            flex: 1 1 700px;
        }
    }

    .toolbar-left {
        .search-toolbar-area {
            @include bp(lg) {
                padding-left: 15px;
            }

            @include bp(sm) {
                margin-top: 0;
            }

            >.v-input input {
                @include bp(lg) {
                    min-width: 180px;
                }
            }
        }
    }

    .toolbar-right {
        display: flex;

        label {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #6a6a6a;
        }

        .category-select {
            margin-left: 15px;
            padding-left: 15px;

            // border-left: 1px solid var(--border-color);
            .v-text-field--outlined.v-input--dense .v-label--active {
                display: none;
            }

            .v-text-field .v-select__slot .v-label {
                position: absolute !important;
            }

            .filter-select .v-select__selections {
                min-width: 130px;
            }

            .v-text-field--outlined.v-input--dense .v-label {
                top: 14px;
            }
        }

        .action {
            margin-left: 15px;
            padding-left: 15px;
            border-left: 1px solid var(--border-color);

            @include bp(md) {
                display: none;
            }
        }

        .filter-select {
            .v-menu__content {
                z-index: 11;
            }
        }
    }

    .v-data-table__wrapper {
        &.removeOverflow {
            overflow: initial;

            table {
                overflow: initial;
            }
        }
    }

    .bottom-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .tab-wrap {
            margin-top: 5px;

            position: relative;
            border-bottom: 1px solid #e7e7e7;
            display: inline-block;

            .v-ripple__container {
                display: none !important;
            }

            .v-tab {
                background-color: transparent;
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                padding-left: 10px;
                padding-right: 10px;
                box-shadow: none;

                &:not(:last-child) {
                    margin-right: 15px;
                }

                &:hover,
                &:focus {
                    &::before {
                        opacity: 0;
                    }
                }

                &:not(.v-tab--active) {
                    color: #565656;
                }

                &.v-tab--active {
                    color: var(--primary-color);
                }
            }

            .v-tabs-slider {
                border-radius: 4px 4px 0px 0px;
            }
        }

        .right-area {
            display: inline-flex;
            align-items: center;

            .action {
                display: none;
                margin-left: 10px;

                @include bp(md) {
                    display: block;
                }
            }

            .nutrition-import-area {
                margin-right: 10px;

                .form-title {
                    display: none;
                }

                .field-wrap {
                    display: inline-flex;
                    align-items: center;

                    .file-area {
                        display: flex;

                        >label {
                            flex: 1 1 100%;
                            max-width: 108px;
                            border: 1px solid var(--input-border);
                            border-radius: 16px 0 0 16px;
                            padding: 10px 15px;
                        }
                    }

                    .file-name {
                        margin-right: 10px;
                        border: 1px solid var(--input-border);
                        border-radius: 0 16px 16px 0px;
                        padding: 10px 15px;
                        display: inline-block;
                        min-width: 216px;
                        width: 100%;
                        max-width: 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-left: -1px;
                    }

                    .actions {
                        button.btn {
                            position: relative;

                            &:disabled {
                                background-color: var(--primary-color);
                            }

                            .loading-dots {
                                position: relative;
                                top: 1px;
                                right: unset;
                                left: unset;
                            }
                        }
                    }
                }

                .import-action {
                    >input {
                        display: none;
                    }
                }
            }
        }

        .switch {
            position: relative;
            display: inline-block;
            margin: 0 5px;
        }

        .switch>span {
            position: absolute;
            top: 9px;
            pointer-events: none;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
            width: 50%;
            text-align: center;
        }

        .switch input.check-toggle-round-flat:checked~.off {
            color: var(--primary-color);
        }

        .switch input.check-toggle-round-flat:checked~.on {
            color: #fff;
        }

        .switch>span.on {
            left: 0;
            padding-left: 2px;
            color: var(--primary-color);
        }

        .switch>span.off {
            right: 0;
            padding-right: 4px;
            color: #fff;
        }

        .switch .check-toggle {
            position: absolute;
            margin-left: -9999px;
            visibility: hidden;
        }

        .switch .check-toggle+label {
            display: block;
            position: relative;
            cursor: pointer;
            outline: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .switch input.check-toggle-round-flat+label {
            padding: 2px;
            width: 100px;
            height: 35px;
            background-color: var(--primary-color);
            -moz-border-radius: 60px;
            -ms-border-radius: 60px;
            -o-border-radius: 60px;
            border-radius: 60px;
        }

        .switch input.check-toggle-round-flat+label:after {
            display: block;
            position: absolute;
            content: "";
        }

        .switch input.check-toggle-round-flat+label:before {
            top: 2px;
            left: 2px;
            bottom: 2px;
            right: 2px;
            background-color: var(--primary-color);
            -moz-border-radius: 60px;
            -ms-border-radius: 60px;
            -o-border-radius: 60px;
            border-radius: 60px;
        }

        .switch input.check-toggle-round-flat+label:after {
            top: 4px;
            left: 4px;
            bottom: 4px;
            width: 48px;
            height: 28px;
            background-color: #fff;
            -webkit-border-radius: 52px;
            -moz-border-radius: 52px;
            -ms-border-radius: 52px;
            -o-border-radius: 52px;
            border-radius: 52px;
            -webkit-transition: margin 0.2s;
            -moz-transition: margin 0.2s;
            -o-transition: margin 0.2s;
            transition: margin 0.2s;
            border-color: var(--primary-color);
        }

        .switch input.check-toggle-round-flat:checked+label:after {
            margin-left: 44px;
        }
    }

    .desc-wrap {
        position: relative;

        &.show {
            .short-content {
                text-decoration: underline;
            }

            .long-content {
                display: block;
                z-index: 111;
            }

            +.overlay {
                z-index: 11;
                position: fixed;
                z-index: -1111;
                top: 0;
                width: 100%;
                left: 0;
                height: 100%;
            }
        }

        .short-content {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .long-content {
            display: none;
            position: absolute;
            left: 50%;
            top: calc(100% + 10px);
            transform: translateX(-50%);
            background: #ffffff;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
            border-radius: 14px;
            padding: 20px 25px;
            z-index: -11;
            width: 100%;
            min-width: 318px;
        }
    }

    .overlay {}
}

.section-nutrition-create {
    .v-dialog {
        overflow-y: initial;
    }

    .panel-header {
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1010px;

        .panel-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #181818;
            font-family: "Rubik";
            text-transform: initial;
        }

        .title-wrap {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .switch {
            position: relative;
            display: inline-block;
            margin: 0 5px;
        }

        .switch>span {
            position: absolute;
            top: 9px;
            pointer-events: none;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
            width: 50%;
            text-align: center;
        }

        .switch input.check-toggle-round-flat:checked~.off {
            color: var(--primary-color);
        }

        .switch input.check-toggle-round-flat:checked~.on {
            color: #fff;
        }

        .switch>span.on {
            left: 0;
            padding-left: 0px;
            color: var(--primary-color);
        }

        .switch>span.off {
            right: 0;
            padding-right: 8px;
            color: #fff;
        }

        .switch .check-toggle {
            position: absolute;
            margin-left: -9999px;
            visibility: hidden;
        }

        .switch .check-toggle+label {
            display: block;
            position: relative;
            cursor: pointer;
            outline: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .switch input.check-toggle-round-flat+label {
            padding: 2px;
            width: 150px;
            height: 35px;
            background-color: var(--primary-color);
            -moz-border-radius: 60px;
            -ms-border-radius: 60px;
            -o-border-radius: 60px;
            border-radius: 60px;
        }

        .switch input.check-toggle-round-flat+label:after {
            display: block;
            position: absolute;
            content: "";
        }

        .switch input.check-toggle-round-flat+label:before {
            top: 2px;
            left: 2px;
            bottom: 2px;
            right: 2px;
            background-color: var(--primary-color);
            -moz-border-radius: 60px;
            -ms-border-radius: 60px;
            -o-border-radius: 60px;
            border-radius: 60px;
        }

        .switch input.check-toggle-round-flat+label:after {
            top: 4px;
            left: 4px;
            bottom: 4px;
            width: 66px;
            height: 28px;
            background-color: #fff;
            -webkit-border-radius: 52px;
            -moz-border-radius: 52px;
            -ms-border-radius: 52px;
            -o-border-radius: 52px;
            border-radius: 52px;
            -webkit-transition: margin 0.2s;
            -moz-transition: margin 0.2s;
            -o-transition: margin 0.2s;
            transition: margin 0.2s;
            border-color: var(--primary-color);
        }

        .switch input.check-toggle-round-flat:checked+label:after {
            margin-left: 65px;
            width: 77px;
        }
    }

    .panel-body {
        max-width: 1010px;

        div.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
            transform: rotate(180deg);
        }

        .v-application {
            max-width: 1080px;
        }

        .title-wtih-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
        }

        .dose-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: var(--title-color);
            margin-bottom: 0;
        }

        .form-wrap {
            background: #ffffff;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
            border-radius: 26px;
            padding: 30px;

            &.new {
                border: 1px dashed #e7e7e7;
                box-shadow: none;
                margin-top: 30px;
            }
        }

        .drop {
            label.btn {
                margin-bottom: 0;
                width: 100%;
            }

            .image {
                height: 100%;

                .img-wrap {
                    min-height: 160px;
                    border-radius: 16px;
                    overflow: hidden;
                }

                .btn-remove {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cpath d='M7.64539 6.50286L12.7534 1.39425C13.5176 0.629994 12.3269 -0.4745 11.6059 0.246595L6.49786 5.35266L1.38728 0.246595C0.625639 -0.517664 -0.481272 0.673158 0.239744 1.39425L5.34779 6.50286L0.237205 11.6115C-0.496505 12.3453 0.638333 13.5056 1.38474 12.7591L6.49532 7.65052L11.6059 12.7591C12.3396 13.4929 13.4897 12.3453 12.7534 11.6115L7.64539 6.50286Z' fill='white'/%3E%3C/svg%3E");
                    font-size: 0;
                    width: 22px;
                    height: 22px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: #00000066;
                    border-radius: 5px;
                    background-repeat: no-repeat;
                }
            }
        }

        .form-group {
            margin-bottom: 20px;

            &.last {
                margin-bottom: 0;
            }

            label {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #989898;

                &:not(.v-label) {
                    margin-bottom: 8px;
                }
            }

            .v-select.v-text-field.v-text-field--outlined>.v-input__control>.v-input__slot {
                min-height: 48px;
            }

            .v-select.v-text-field .v-input__slot fieldset {
                max-height: 48px;
                min-height: 48px;

                &:focus {
                    border-color: var(--primary-color);
                }
            }

            .v-select.v-text-field.v-input--is-focused .v-input__slot fieldset {
                border-color: var(--primary-color);
            }

            .v-select.v-text-field.v-text-field--enclosed .v-input__append-inner {
                margin-top: 14px;
            }

            input[type="text"] {
                padding: 11px 10px;
                max-height: 48px;
                line-height: 1.5;

                &::placeholder {
                    color: #989898;
                }

                &:focus {
                    border-color: var(--primary-color);
                }
            }

            .v-select__selections {
                >input {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .v-select__slot>input {
                padding-left: 0 !important;
            }

            >.v-input {
                margin-top: 0;
                padding-top: 0;
            }

            .v-textarea {
                .v-input__slot {
                    margin-bottom: 0;
                }

                +.char-limit {
                    text-align: right;
                    display: block;
                    color: var(--primary-color);
                }
            }

            .v-select__slot {
                input {
                    border: none;
                }
            }

            .v-messages,
            .v-text-field__details {
                min-height: auto;
            }
        }

        .v-menu__content .v-list .add-category-text {
            .v-list-item__content {
                border-top: 1px solid var(--border-color);
                padding-top: 10px;
                margin-top: 5px;
            }
        }

        .tab-wrap {
            margin-bottom: 15px;
            position: relative;
            border-bottom: 1px solid #e7e7e7;

            .v-ripple__container {
                display: none !important;
            }

            .v-tab {
                background-color: transparent;
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                padding-left: 10px;
                padding-right: 10px;
                box-shadow: none;

                &:not(:last-child) {
                    margin-right: 15px;
                }

                &:hover,
                &:focus {
                    &::before {
                        opacity: 0;
                    }
                }

                &:not(.v-tab--active) {
                    color: #565656;
                }

                &.v-tab--active {
                    color: var(--primary-color);
                }
            }

            .v-tabs-slider {
                border-radius: 4px 4px 0px 0px;
            }
        }

        .radio-group-wrap {
            display: inline-flex;
            padding-right: 55px;

            @include bp(md) {
                padding-right: 45px;
            }

            @include bp(sm) {
                padding-right: 35px;
            }

            @include bp(t) {
                margin-bottom: 15px;

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }

            >label {
                display: inline-block;

                @include bp(t) {
                    width: 85px;
                }
            }

            .v-label {
                color: var(--title-color);
                font-weight: 400;
                font-size: 16px;
            }

            .v-input--selection-controls {
                margin-top: 0;
                padding-left: 20px;
                padding-top: 0;
            }

            .v-input--selection-controls__ripple:before {
                display: none;
            }

            .v-ripple__container {
                display: none;
            }

            .v-input--selection-controls__input {
                margin-right: 15px;
            }
        }

        .v-text-field__slot {
            textarea {
                min-height: 116px;
                line-height: 1.4;
            }
        }

        .btn-primary {
            height: 48px;
            padding: 12px 32px;
            border-radius: 12px;
            position: relative;

            &:disabled {
                background-color: var(--primary-color);
            }

            .loading-dots {
                position: relative;
                top: 0;
                left: 6px;
            }
        }
    }
}

.add-category {
    box-shadow: none !important;
    border-radius: 25px !important;

    .v-card__title {
        .modal-title {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            color: var(--title-color);
        }
    }

    .v-card__title {
        padding: 25px 30px !important;
        padding-bottom: 0 !important;
    }

    .modal-body {
        padding: 0 30px;
        padding-top: 20px;
    }

    .modal-footer {
        padding: 25px 30px;
        padding-top: 0;
        border: none;
        justify-content: flex-start;

        .actions {
            margin: 0;
        }

        .btn-primary {
            &:disabled {
                background-color: var(--primary-color);
            }
        }
    }
}

.add-categories {
    border-radius: 20px !important;
    overflow-x: hidden;

    .modal-head {
        padding: 20px 30px;
        padding-bottom: 0px;

        .modal-title {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            color: var(--title-color);
        }
    }

    .modal-body {
        padding: 30px 30px;
        padding-top: 25px;
        padding-bottom: 0;

        .form-group {
            margin-bottom: 20px;
        }

        .drop {
            label.btn {
                margin-bottom: 0;
                width: 100%;
            }

            .image {
                height: 100%;

                .img-wrap {
                    min-height: 160px;
                    border-radius: 16px;
                    overflow: hidden;
                }

                .btn-remove {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cpath d='M7.64539 6.50286L12.7534 1.39425C13.5176 0.629994 12.3269 -0.4745 11.6059 0.246595L6.49786 5.35266L1.38728 0.246595C0.625639 -0.517664 -0.481272 0.673158 0.239744 1.39425L5.34779 6.50286L0.237205 11.6115C-0.496505 12.3453 0.638333 13.5056 1.38474 12.7591L6.49532 7.65052L11.6059 12.7591C12.3396 13.4929 13.4897 12.3453 12.7534 11.6115L7.64539 6.50286Z' fill='white'/%3E%3C/svg%3E");
                    font-size: 0;
                    width: 22px;
                    height: 22px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: #00000066;
                    border-radius: 5px;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .modal-footer {
        padding: 20px 30px;
        padding-top: 0;
        border-top: none;

        .actions {
            margin: 0;
        }

        .btn-primary {
            &:disabled {
                background-color: var(--primary-color);
            }
        }

        .loading-dots {
            position: relative;
            top: 1px;
            left: unset;
        }
    }
}

/*End  nutrition scss */

/* Vaccine scss */
.section-vaccine {
    .panel {
        max-width: 1010px;
    }
    .panel-header {
        padding-bottom: 30px;
        &.sticky {
            top: 75px;
            z-index: 11;
            position: -webkit-sticky;
            position: sticky;
            background-color: white;
            padding: 10px 0;
            border-bottom: 1px solid var(--input-border);
        }
        .panel-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #181818;
            font-family: "Rubik";
            text-transform: initial;
        }
        .title-wrap {
            display: flex;
            justify-content: space-between;
        }
        .btn-save {
            padding: 12px calc(32px - 0.07px);
        }
    }
    .panel-body {
        div.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
            transform: rotate(180deg);
        }
        .v-application {
            max-width: 1080px;
        }
        .title-wtih-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
        }
        .dose-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: var(--title-color);
            margin-bottom: 0;
        }
        .form-wrap {
            background: #ffffff;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
            border-radius: 26px;
            padding: 30px;
            &.new {
                border: 1px dashed #e7e7e7;
                box-shadow: none;
                margin-top: 30px;
            }
        }
        .form-group {
            margin-bottom: 20px;
            &.last {
                margin-bottom: 0;
            }
            label {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #989898;
                margin-bottom: 8px;
            }
            .v-select.v-text-field.v-text-field--outlined > .v-input__control > .v-input__slot {
                min-height: 48px;
            }
            .v-select.v-text-field .v-input__slot fieldset {
                max-height: 48px;
                min-height: 48px;
                &:focus {
                    border-color: var(--primary-color);
                }
            }
            .v-select.v-text-field.v-input--is-focused .v-input__slot fieldset {
                border-color: var(--primary-color);
            }

            .v-select.v-text-field.v-text-field--enclosed .v-input__append-inner {
                margin-top: 14px;
            }
            input[type="text"] {
                padding: 11px 10px;
                max-height: 48px;
                line-height: 1.5;
                &::placeholder {
                    color: #989898;
                }
                &:focus {
                    border-color: var(--primary-color);
                }
            }
            .v-select__slot > input {
                padding-left: 0 !important;
            }
            > .v-input {
                margin-top: 0;
                padding-top: 0;
            }

            .v-select__slot {
                input {
                    border: none;
                }
            }
            .v-messages,
            .v-text-field__details {
                min-height: auto;
            }
            .req-booster {
                display: none;
                &.show {
                    display: inline-block;
                }
            }
        }
        .btn-save {
            height: 48px;
            left: 340px;
            border-radius: 12px;
            width: 100px;
        }
        .btn-add {
            margin-top: 30px;
            > span {
                margin-right: 6px;
            }
        }
    }
}
/* End Vaccine scss */

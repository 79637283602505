/* Vue select custom */
.v-select {
    ul.vs__dropdown-menu {
        padding-left: 0;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        &::before {
            content: "";
            width: 15px;
            height: 100%;
            position: absolute;
            right: 0;
            bottom: 0;
            border: none;
            background: white;
        }
        &::after {
            content: "";
            width: 15px;
            height: 100%;
            position: absolute;
            right: 0;
            bottom: 0;
            border: none;
            background: white;
        }

        .vs__dropdown-option {
            position: relative;
            &:not(:last-child) {
                border-bottom: 1px solid var(--input-border);
            }
            &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' viewBox='0 0 14 11' fill='none'%3E%3Cpath d='M1 5.42105L5.42105 9.8421L13 1' stroke='%23E77386' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                position: absolute;
                right: 37px;
                top: 50%;
                transform: translateY(-50%);
                width: 15px;
                height: 10px;
                display: inline-block;
                opacity: 0;
            }
            &.vs__dropdown-option--selected {
                background-color: transparent;
                color: var(--primary-color);
                position: relative;
                &::after {
                    opacity: 1;
                }
            }
            // &.vs__dropdown-option--highlight {
            //     &::after {
            //         opacity: 1;
            //     }
            // }
        }
    }
}
.v-list{
    padding-top: 10px;
    padding-bottom: 10px;
}
.vs__dropdown-toggle {
    cursor: pointer;
}
svg.vs__open-indicator {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'%3E%3Cpath d='M5.37796 5.56356C5.17858 5.79379 4.82142 5.79379 4.62204 5.56356L0.520335 0.827326C0.239896 0.503504 0.469924 -9.20569e-07 0.8983 -8.83119e-07L9.1017 -1.65954e-07C9.53008 -1.28505e-07 9.7601 0.503505 9.47967 0.827327L5.37796 5.56356Z' fill='%23565656'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    path {
        display: none;
    }
}
.vs--single {
    font-size: 14px;

    .vs__search {
        position: absolute;
        display: none;
    }
    .vs__dropdown-toggle {
        border: 1px solid var(--input-border);
        min-height: 44px;
        border-radius: 16px;
    }
    .vs__dropdown-option {
        font-weight: 500;
        color: var(--text-color);
        padding: 12px 15px;
    }
    .vs__dropdown-option--highlight {
        background-color: transparent;
        color: var(--primary-color);
    }
}

.v-select.v-text-field {
    .v-text-field__details {
        display: none;
    }
    .v-input__slot {
        margin-bottom: 0;
        fieldset {
            border: 1px solid var(--input-border);
            margin: 0;
            border-radius: 16px;
            padding: 0;
            max-height: 44px;
            top: 0;
        }
    }
    .v-select__slot {
        .v-select__selections {
            padding: 0px;
            background-color: #fff;
            font-size: 16px;
        }
    }
    input {
        &[readonly="readonly"] {
            border: none;
            padding: 0;
            width: auto;
        }
    }
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 44px;
    }
    &.v-text-field--enclosed .v-input__append-inner {
        margin-top: 10px;
    }
}
/* End  Vue select custom */

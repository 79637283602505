@mixin link {
}

@mixin box-shadow($value) {
    -webkit-box-shadow: inset $value;
    -moz-box-shadow: inset $value;
    box-shadow: inset $value;
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin text-field {
    display: inline-block;
    outline: none;
    text-decoration: none;
    font: 14px/100% Arial, Helvetica, sans-serif;
    padding: 0.5em;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

@mixin bp($point) {
    @if $point==largedesktop {
        @media (max-width: 1660px) {
            @content;
        }
    } @else if $point==lg {
        @media (max-width: 1440px) {
            @content;
        }
    } @else if $point==md {
        @media (max-width: 1366px) {
            @content;
        }
    } @else if $point==sm {
        @media (max-width: 1280px) {
            @content;
        }
    } @else if $point==t {
        @media (max-width: 1024px) {
            @content;
        }
    } @else if $point==bt {
        @media (max-width: 991px) {
            @content;
        }
    } @else if $point==bm {
        @media (max-width: 767px) {
            @content;
        }
    } @else if $point==m {
        @media (max-width: 575px) {
            @content;
        }
    }
}

@mixin up($point) {
    @if $point==largedesktop {
        @media (min-width: 1661px) {
            @content;
        }
    } @else if $point==lg {
        @media (min-width: 1441px) {
            @content;
        }
    } @else if $point==md {
        @media (min-width: 1367px) {
            @content;
        }
    } @else if $point==sm {
        @media (min-width: 1281px) {
            @content;
        }
    } @else if $point==t {
        @media (min-width: 1025px) {
            @content;
        }
    } @else if $point==bt {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $point==bm {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $point==m {
        @media (min-width: 576px) {
            @content;
        }
    }
}

/* panel scss */
.panel {
    min-height: 75vh;
    &-title {
        font-weight: 950;
        font-size: 20px;
        line-height: 28px;
        text-transform: uppercase;
        color: var(--title-color);
        margin-bottom: 20px;
        font-family: "Eveleth Bold";
    }

    .title-with-back {
        display: flex;
        align-items: center;
        .back-arrow {
            width: 36px;
            height: 36px;
            background: #ffffff;
            box-shadow: 0px 0px 30px rgb(0 0 0 / 12%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 25px;
        }
        .panel-title {
            margin-bottom: 0;
        }
    }
}
/* End panel scss */

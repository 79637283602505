/* users scss */
.section-user {
    .v-data-table {
        table {
            tbody {
                .thumb-wrap {

                }
                .name.link{
                    color: var(--text-color) !important;
                }
            }
        }
    }
    nav[aria-label="Pagination Navigation"] {
        .v-pagination {
            justify-content: flex-end;
            li {
                &:last-child {
                    button {
                        margin-right: 0;
                    }
                }
                button {
                    box-shadow: none;
                    color: var(--text-color);
                    padding: 0;
                    min-width: 22px;

                    &.v-pagination__navigation {
                        border: 1px solid #6a6a6a;
                        border-radius: 10px;
                        &--disabled {
                            border-color: #e7e7e7;
                            opacity: 1;
                            .v-icon {
                                color: #e7e7e7;
                            }
                        }
                    }
                    &.v-pagination__item {
                        &--active {
                            background-color: transparent !important;
                            color: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
}






/* End users scss */

/* Sidebar scss */
.bd-sidebar {
    position: fixed;
    top: 75px;
    left: 0;
    width: 100%;
    z-index: 1;
    width: 300px;
    height: calc(100% - 75px);
    @include bp(sm) {
        width: 260px;
    }

    .sidebar {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 0px;
        height: 100%;
        &-wrap {
            padding-top: 40px;
            overflow: auto;
            padding-bottom: 20px;
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #ccc;
            }
        }
    }
    .nav-link {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #565656;
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        padding-left: 45px;
        &::before {
            content: "";
            width: 40px;
            height: 40px;
            background: #fbedf1;
            border-radius: 12px;
            position: absolute;
            left: 35px;
            top: 12px;
            opacity: 0;
            visibility: hidden;
            z-index: -1;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 3px;
            height: calc(100% - 40px);
            display: inline-block;
            background: var(--primary-color);
            border-radius: 0px 10px 10px 0px;
            opacity: 0;
            visibility: hidden;
        }
        span {
            margin-bottom: 0;
        }

        &.router-link-active,
        &:hover,
        &:focus {
            background-color: transparent;
            color: var(--primary-color);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            &::after {
                opacity: 1;
                visibility: visible;
            }
            svg {
                path {
                    fill: var(--primary-color);
                }
            }
        }
        svg {
            width: 20px;
            margin-right: 20px;
        }
    }
}
/*End Sidebar scss */

/* breed scss */
.section-breed {
    .title-with-tab {
        .tab-wrap {
            margin-top: 15px;
            position: relative;
            border-bottom: 1px solid #e7e7e7;
            .v-ripple__container {
                display: none !important;
            }
            .v-tab {
                background-color: transparent;
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                padding-left: 10px;
                padding-right: 10px;
                box-shadow: none;
                &:not(:last-child) {
                    margin-right: 15px;
                }
                &:hover,
                &:focus {
                    &::before {
                        opacity: 0;
                    }
                }
                &:not(.v-tab--active) {
                    color: #565656;
                }
                &.v-tab--active {
                    color: var(--primary-color);
                }
            }


            .v-tabs-slider {
                border-radius: 4px 4px 0px 0px;
            }
        }
    }
}

/* End breed scss */

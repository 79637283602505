/* Date picker scss */
.datepicker-area {
    .v-text-field .v-input__append-inner {
        padding: 0 !important;
        margin: 0 !important;
        .v-input__icon--clear {
            position: absolute;
            right: 10px;
            top: 10px;
            .theme--light.v-icon {
                color: var(--title-color) !important;
            }
        }
    }
    .v-text-field {
        margin-top: 0;
        padding-top: 0;
    }
    .v-input__slot {
        margin-bottom: 0;
    }
    .v-date-picker-title__date {
        font-size: 20px;
    }
}
/*End Date picker scss */

.v-textarea {
    .v-text-field--outlined {
    }
    .v-text-field__slot{
        textarea{
            padding: 12px;
        }
    }
    &.v-input--is-focused {
        textarea {
            border:1px solid var(--primary-color);
        }
    }
}

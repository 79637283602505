/* Table footer */
.table-footer {
    .table-footer-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .widget {
        &:not(:first-child) {
            margin-right: 15px;
        }
    }
    .widget-total {
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #565656;
        }
    }
    .pagination-showing {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--title-color);
        display: inline-block;
        margin: 0 5px;
        .primary-color {
            font-weight: 500;
            color: var(--primary-color);
        }
    }
    ul.pagination {
        align-items: center;
        .page-item {
            margin: 0 5px;
            .page-link {
                border: 1px solid #e7e7e7;
                box-sizing: border-box;
                border-radius: 10px;
                padding: 3px 8px;
                width: 32px;
                text-align: center;
                &:hover {
                    background-color: transparent;
                }
            }
            &.disabled {
                svg path {
                    stroke: #e7e7e7;
                }
            }
            &:not(.disabled) {
                .page-link {
                    &:hover,
                    &:active,
                    &:focus {
                        border-color: #6a6a6a;
                        background-color: transparent;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

/* End Table Footer */
